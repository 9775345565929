import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Query, SanityHistoricalItem } from "@graphql-types";
import { Container, GridContainer } from "@util/standard";
import ItemPreview from "./itemPreview";

interface Props {
  datePeriod: string;
}

function HistoricalItemsGrid(props: Props) {
  const { datePeriod } = props;

  const { allSanityHistoricalItem }: Query = useStaticQuery(graphql`
    {
      allSanityHistoricalItem(sort: { fields: itemCreationDate, order: ASC }) {
        nodes {
          id
          itemName
          itemDate
          itemCreationDate
          hasInnerPage
          containImage
          itemDescription
          displayStyle
          slug {
            current
          }
          itemFeatureImage {
            asset {
              fluid(maxWidth: 1000) {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  if (allSanityHistoricalItem == null) {
    return null;
  }

  const sortArray = (arr: Array<SanityHistoricalItem>) => {
    return arr.filter(
      (item: SanityHistoricalItem) => item.itemDate === datePeriod
    );
  };

  const sortedItems = sortArray(allSanityHistoricalItem.nodes);

  const [currentExpandedItem, setCurrentExpandedItem] = useState<number>();
  const [itemOpenOnLoad, setItemOpenOnLoad] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sortedItems.map((item, index: number) => {
        const isItemInUrl = `#${item.id}` === window.location.hash;
        if (isItemInUrl && currentExpandedItem !== index && itemOpenOnLoad) {
          setTimeout(() => {
            setCurrentExpandedItem(index);
            setItemOpenOnLoad(false);
          }, 500);
        }
      });
    }
  });

  return (
    <Container
      width="85%"
      margin=" 0px auto 100px auto"
      tabletMargin="0px auto 420px auto"
      mobileMargin="0px auto 200px auto"
    >
      <GridContainer gridTemplateColumns="repeat(3,1fr)" tabletRepeat={1}>
        {sortedItems.map((item, index: number) => {
          const urlHash =
            typeof window !== "undefined" ? window.location.hash : "";
          const isItemInUrl = `#${item.id}` === urlHash;
          if (item.itemDate === datePeriod) {
            return (
              <div onClick={() => setCurrentExpandedItem(index)} id={item.id}>
                <ItemPreview
                  item={item}
                  key={item.id}
                  index={index}
                  disableExpandedView={index !== currentExpandedItem}
                  isHistory
                  id={item.id}
                  openOnLoad={isItemInUrl}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </GridContainer>
    </Container>
  );
}

export default HistoricalItemsGrid;
