import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Container, GridContainer, P } from "@util/standard";
import { IMAC_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import MobileTimeline from "./mobileTimeline";
import HistoricalItemsGrid from "./historicalItemsGrid";
import { navigate } from "gatsby";
import { scrollToItem } from "@util/helper";

const CircleEdge = styled.div<{ isHighlighted: boolean }>`
  width: 28px;
  height: 28px;
  background-color: transparent;
  margin: auto;
  border-radius: 50%;

  ${({ isHighlighted }) =>
    isHighlighted
      ? `  border: 2px solid black; 
`
      : `  border: 2px solid transparent;
`};
  transition-timing-function: ease-in;
  transition: border 1s;
`;

const Circle = styled.div<{ highlighted?: boolean; isBorder?: boolean }>`
  border-radius: 50%;
  ${({ highlighted }) =>
    highlighted ? `background-color: black;` : `background-color: #9FA4B6;`};
  ${({ isBorder }) => isBorder && ``}
  height: 16px;
  width: 16px;
  margin: 5px auto;
  transition-timing-function: ease-in;
  transition: all 1s;
`;

const Line = styled.div<{
  highlighted: boolean;
  isLast: boolean;
  isFirst: boolean;
}>`
  width: 100%;
  height: 2px;
  ${({ highlighted, isFirst, isLast }) =>
    highlighted && !isFirst && !isLast
      ? `background: linear-gradient(45deg, transparent -13%,black 50%, transparent 100%); opacity: 1;  width:196%;`
      : highlighted && isFirst
      ? `background:linear-gradient(90deg,#060F25 3%,#FFFFFF 100%);`
      : highlighted && isLast
      ? `background:linear-gradient(48deg,transparent -40%,black 100%);`
      : `background-color:#9FA4B6;opacity: 0.3;`};

  position: absolute;
  top: 14px;
  left: -48%;
  @media only screen and (max-width: ${IMAC_BREAKPOINT}px) {
    left: -46%;
  }
  ${({ isFirst }) => isFirst && `right: 0px; width: 100%; left:52% !important;`}
  ${({ isLast }) => isLast && ` left:-47%; width: 97%; `}
  transition-timing-function: ease-in;
  transition: all 1s;
`;

const DateText = styled(P)<{ highlighted: boolean }>`
  color: #9fa4b6;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  ${({ highlighted }) => highlighted && `color: black; font-size: 18px;`};
  transition-timing-function: ease-in;
  transition: all 1s;
`;

function Timeline() {
  const datePeriods = [
    "c.1400-1970",
    "1971-1980",
    "1981-1990",
    "1991-2000",
    "2001-2010",
    "2011-2020",
    "2021-Today",
  ];

  const [currentTimePeriod, setCurrentTimePeriod] = useState("c.1400-1970");
  const [isTabletWidth, setIsTabletWidth] = useState<boolean>();
  const [currentScreenHeight, setCurrentScreenHeight] = useState<number>();
  const [timelineIsFixed, setTimelineIsFixed] = useState(false);

  const checkForTabletWidth = () => {
    window.innerWidth <= TABLET_BREAKPOINT
      ? setIsTabletWidth(true)
      : setIsTabletWidth(false);
  };

  const checkScrollYValue = () => {
    if (
      currentScreenHeight !== undefined &&
      currentScreenHeight <= window.scrollY
    ) {
      // TEMPORARILY DISABLING FIXED TIMELINE
      setTimelineIsFixed(false);
    } else {
      setTimelineIsFixed(false);
    }
  };

  useEffect(() => {
    if (typeof window !== null) {
      setCurrentScreenHeight(window.innerHeight);
      checkForTabletWidth();
      checkScrollYValue();
      window.addEventListener("resize", checkForTabletWidth);
      window.addEventListener("scroll", checkScrollYValue);
    }

    if (window.location.search !== "") {
      const formatSearchParam = window.location.search.substring(1);
      const formatLocationHash = window.location.hash.substring(1);
      setCurrentTimePeriod(formatSearchParam);
      setTimeout(() => {
        scrollToItem(formatLocationHash + 100);
      }, 100);
    }
  }, []);

  return (
    <Container margin="0px 0px 400px 0px">
      {!isTabletWidth && (
        <GridContainer
          width={timelineIsFixed ? "85%" : "90%"}
          margin="50px auto"
          tabletRepeat={datePeriods.length}
          gridTemplateColumns={`repeat(${datePeriods.length},1fr)`}
          position={timelineIsFixed ? "fixed" : "inherit"}
          top={timelineIsFixed ? "-25px" : ""}
          left={timelineIsFixed ? "9%" : ""}
          zIndex={timelineIsFixed ? 4 : 1}
        >
          {datePeriods.map((date, index) => {
            const isHighlightedDate = date === currentTimePeriod;
            return (
              <Container
                height="100%"
                width="100%"
                position="relative"
                margin="0px auto"
                cursor="pointer"
                onClick={() => setCurrentTimePeriod(date)}
                key={date}
              >
                <CircleEdge isHighlighted={isHighlightedDate}>
                  <Circle highlighted={isHighlightedDate} />
                </CircleEdge>
                <Line
                  highlighted={isHighlightedDate}
                  isFirst={index === 0}
                  isLast={index === datePeriods.length - 1}
                />
                <DateText highlighted={isHighlightedDate}>{date}</DateText>
              </Container>
            );
          })}
        </GridContainer>
      )}
      {/* TEMPORARILY DISABLING FIXED TIMELINE */}
      {isTabletWidth && <MobileTimeline timelineIsFixed={false} />}
      {!isTabletWidth && <HistoricalItemsGrid datePeriod={currentTimePeriod} />}
    </Container>
  );
}

export default Timeline;
