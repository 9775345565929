import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Timeline from "@components/history/timeline";
import Hero from "@components/shared/hero/hero";
import { Query } from "@graphql-types";
import SEO from "@components/shared/seo";

function History() {
  const { sanityHistoryLanding }: Query = useStaticQuery(graphql`
    {
      sanityHistoryLanding {
        historyHero {
          heroHeader
          useVideo
          heroVideo {
            vimeoUrl
            uploadedVideo {
              asset {
                url
              }
            }
          }
          ctaScrollButtonText
          heroSubText {
            _rawContent
          }
          heroBackgroundImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
            }
          }
          heroMobileBackgroundImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        seoBlock {
          pageTitle
          slug {
            current
          }
          ogImage {
            asset {
              fluid {
                srcWebp
                srcSet
                srcSetWebp
                src
              }
            }
          }
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  if (sanityHistoryLanding == null) {
    return null;
  }
  const { historyHero, seoBlock } = sanityHistoryLanding;
  if (historyHero == null) {
    return null;
  }
  return (
    <>
      <SEO seoData={seoBlock} slug={seoBlock?.slug?.current ?? "/history"} />
      <Hero heroContent={historyHero} headerIsVisible isHistoryPage />
      <Timeline />
    </>
  );
}

export default History;
