import React, { useContext, useState } from "react";
import styled from "styled-components";
import Img, { FluidObject } from "gatsby-image";
import { navigate } from "gatsby";

import { AnimationContainer, IconButton } from "@components/shared/sub";
import { Container, H3, P } from "@util/standard";
import {
  animationTypes,
  colors,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { SanityHistoricalItem } from "@graphql-types";
import ExpandedTab from "@components/shared/expandedTab";
import ModalBox from "@components/shared/modalBox";
import { ScreenSizeContext } from "@components/shared/screenWidthProvider";
import PopUpContentBlock from "@components/shared/sub/popUpContentBlock";

interface Props {
  item: SanityHistoricalItem;
  index: number;
  disableExpandedView?: boolean;
  isHistory?: boolean;
  id?: string;
  openOnLoad?: boolean;
}

const TextContainer = styled(Container)<{ hoverEffect: boolean }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 10%;
  bottom: 10%;

  ${({ hoverEffect }) => (hoverEffect ? `opacity: 1;` : `opacity: 0;`)};
  transition: all 0.2s;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    opacity: 1;
    h3 {
      font-size: 21px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    opacity: 1;
    h3 {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
    }
  }
`;

const Overlay = styled.div<{ hoverEffect: boolean }>`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;

  background: rgba(6, 15, 37, 0.55);

  ${({ hoverEffect }) => (hoverEffect ? `opacity: 0.5;` : `opacity: 1;`)};
  transition: all 0.2s;
`;

const StyledImg = styled(Img)<{ hoverEffect: boolean }>`
  width: 100%;
  height: 100%;
  border: 4px solid white;
`;

function ItemPreview(props: Props) {
  const { item, index, disableExpandedView, isHistory, id, openOnLoad } = props;

  const [hoverEffectOn, setHoverEffect] = useState(false);
  const [expandedViewOpen, setExpandedViewOpen] = useState(openOnLoad);
  const [modalVisible, setModalVisible] = useState(openOnLoad);

  const { screenSize } = useContext(ScreenSizeContext);

  const handleOpenExpandedView = (
    item: SanityHistoricalItem,
    index: number
  ) => {
    if (!item.hasInnerPage || item.hasInnerPage == null) {
      setExpandedViewOpen(true);
      setModalVisible(true);
    } else {
      navigate(`${item.slug?.current}`);
    }
  };

  return (
    <Container position="relative">
      {expandedViewOpen && !disableExpandedView && (
        <>
          {!screenSize.isMobileWidth && (
            <ExpandedTab
              name={item.itemName}
              featureImage={item.itemFeatureImage}
              creationDate={item.itemCreationDate}
              datePeriod={item.itemDate ?? "1001-1970"}
              description={item.itemDescription}
              displayStyle={item.displayStyle}
              index={index}
              doubleHeight
              containImage={item.containImage}
              isHistory={isHistory}
              id={id}
            >
              <IconButton
                buttonText="close"
                textColor={colors.dimgray}
                darkVersion={
                  item.displayStyle === "light" || item.displayStyle == null
                }
                reversed
                icon="closeIcon"
                onClick={() => setExpandedViewOpen(false)}
              />
            </ExpandedTab>
          )}
          {screenSize.isMobileWidth && (
            <ModalBox visible={modalVisible} setVisible={setModalVisible}>
              <PopUpContentBlock
                name={item.itemName}
                featureImage={item.itemFeatureImage}
                creationDate={item.itemCreationDate}
                description={item.itemDescription}
                displayStyle={item.displayStyle}
                doubleHeight
                isHistory={isHistory}
                id={id}
                datePeriod={item.itemDate ?? "1001-1970"}
              />
            </ModalBox>
          )}
        </>
      )}
      <AnimationContainer animationType={animationTypes.slideInBottom}>
        <Container
          height="392px"
          width="100%"
          overflow="hidden"
          mobileHeight="195px"
          cursor="pointer"
          onMouseEnter={() => setHoverEffect(true)}
          onMouseLeave={() => setHoverEffect(false)}
          onClick={() => handleOpenExpandedView(item, index)}
        >
          <StyledImg
            fluid={item.itemFeatureImage?.asset?.fluid as FluidObject}
            hoverEffect={hoverEffectOn}
          />
          <TextContainer hoverEffect={hoverEffectOn}>
            <P fontFamily="bold">{item.itemCreationDate}</P>

            <H3
              width="80%"
              fontSize={17}
              lineHeight="20px"
              margin="0px 0px 20px 0px"
              color="white"
              fontFamily="extra-bold"
            >
              {item.itemName?.toUpperCase()}
            </H3>

            <IconButton
              buttonText="Learn More"
              imgHeight={15}
              imgWidth={15}
              onClick={() => handleOpenExpandedView(item, index)}
            />
          </TextContainer>
          <Overlay hoverEffect={hoverEffectOn} />
        </Container>
      </AnimationContainer>
    </Container>
  );
}

export default ItemPreview;
