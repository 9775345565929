import React, { useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import { Container, P } from "@util/standard";
import HistoricalItemsGrid from "./historicalItemsGrid";

interface Props {
  timelineIsFixed?: boolean;
}

const CircleEdge = styled.div<{ isHighlighted: boolean }>`
  width: 28px;
  height: 28px;
  background-color: transparent;
  margin: auto;
  border-radius: 50%;

  ${({ isHighlighted }) =>
    isHighlighted
      ? `  border: 2px solid black;
`
      : `  border: 2px solid transparent;
`};
  transition: border 0.2s;
`;

const Circle = styled.div<{ highlighted: boolean }>`
  border-radius: 50%;
  ${({ highlighted }) =>
    highlighted ? `background-color: black;` : `background-color: #9FA4B6;`};
  height: 16px;
  width: 16px;
  margin: 5px auto;
  transition: all 0.2s;
`;

const Line = styled.div<{
  highlighted: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  width: 100%;
  height: 2px;
  ${({ highlighted, isFirst, isLast }) =>
    highlighted && !isFirst && !isLast
      ? `background:linear-gradient(45deg, transparent -31%, black 50%, transparent 134%);`
      : highlighted && isFirst
      ? `background: linear-gradient(45deg,black 10%,transparent 163%);`
      : highlighted && isLast
      ? `background: linear-gradient(45deg,transparent -82%,black 163%);`
      : `background-color:#9FA4B6;`};

  position: absolute;
  top: 14px;

  ${({ isFirst }) => isFirst && `right: 0px; width: 50%;`}
  ${({ isLast }) => isLast && `left: 0px; width: 50%;`}


  transition: all 0.2s;
`;

const DateText = styled(P)<{ highlighted: boolean }>`
  color: #9fa4b6;
  font-size: 15px;
  text-align: center;

  ${({ highlighted }) => highlighted && `color: black; font-size: 18px;`};
  transition: all 0.2s;
`;

function MobileTimeline(props: Props) {
  const { timelineIsFixed } = props;

  const [currentTimePeriod, setCurrentTimePeriod] = useState("c.1400-1970");
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);

  const datePeriods = [
    "c.1400-1970",
    "1971-1980",
    "1981-1990",
    "1991-2000",
    "2001-2010",
    "2011-2020",
    "2021-Today",
  ];

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: timelineIsFixed ? 1 : 3,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderRef = useRef(null);

  const handleSwipe = (e: string) => {
    if (e === "left") {
      setCurrentTimePeriod(datePeriods[currentTimeIndex + 1]);
      setCurrentTimeIndex(currentTimeIndex + 1);
    } else if (e === "right") {
      setCurrentTimePeriod(datePeriods[currentTimeIndex - 1]);
      setCurrentTimeIndex(currentTimeIndex - 1);
    }
  };

  const handleClick = (date: string, index: number) => {
    setCurrentTimePeriod(date);
    setCurrentTimeIndex(index);
    sliderRef?.current?.slickGoTo(index - 1);
  };

  return (
    <Container>
      <Container
        position={timelineIsFixed ? "fixed" : "inherit"}
        top={timelineIsFixed ? "-15px" : ""}
        left={timelineIsFixed ? "20%" : ""}
        zIndex={timelineIsFixed ? 5 : 1}
        width={timelineIsFixed ? "60%" : "100%"}
        zIndex={timelineIsFixed ? 4 : 1}
      >
        <Slider
          {...sliderSettings}
          ref={sliderRef}
          onSwipe={(e) => handleSwipe(e)}
        >
          {datePeriods.map((date, index) => {
            const isHighlightedDate =
              date === currentTimePeriod && index === currentTimeIndex;
            return (
              <Container
                height="100%"
                width="100%"
                position="relative"
                margin="25px auto"
                cursor="pointer"
                onClick={() => handleClick(date, index)}
                key={date}
              >
                <CircleEdge isHighlighted={isHighlightedDate}>
                  <Circle highlighted={isHighlightedDate} />
                </CircleEdge>

                <Line
                  highlighted={isHighlightedDate}
                  isFirst={index === 0}
                  isLast={index === datePeriods.length - 1}
                />
                <DateText highlighted={isHighlightedDate}>{date}</DateText>
              </Container>
            );
          })}
        </Slider>
      </Container>
      <HistoricalItemsGrid datePeriod={currentTimePeriod} />
    </Container>
  );
}

export default MobileTimeline;
